const plugin = require('tailwindcss/plugin');

const containerQueriesPlugin = plugin(({ matchUtilities, matchVariant, theme }) => {
  matchUtilities(
    {
      container: (value, { modifier }) => ({
        'container-type': value,
        'container-name': modifier,
      }),
    },
    {
      values: {
        inline: 'inline-size',
        normal: 'normal',
        size: 'size',
      },
      modifiers: 'any',
    }
  );

  matchVariant(
    'container',
    (value, { modifier }) => {
      return [`@container`, modifier, value].filter(Boolean).join(' ');
    },
    {
      values: theme('containers') ?? {},
    }
  );
});

module.exports = containerQueriesPlugin;
