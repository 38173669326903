import { OcelotConfig } from '@/types/ocelotConfig';

const BaseConfig: OcelotConfig = {
  search: {
    header: {
      invalid: 'Ogiltig sökning. Prova igen.',
      success: 'Sökresultat för [term]',
      noHits: 'Inga träffar för [term]',
      count: '[count] Resultat',
      error: 'Ojdå! Ett fel uppstod. Prova igen.',
    },
    noTitleText: 'Ingen titel',
    googleCustomSearch: {
      apiKey: 'AIzaSyDzPGtrEiLnkUhfqxc-4zK0xNwMHvvZVSk',
      id: '',
    },
    placeholderImage: '',
  },
  analytics: {
    brandName: '',
    google: {
      gtm: 'GTM-NNQ7462Q',
      dimensions: {
        page_id: '',
        mobile_website: '',
        categories: '',
      },
      viewId: 0,
      hostname: '',
    },
    parsely: {
      siteId: '',
    },
    tealium: {
      account: '',
      profile: '',
      environment: '',
    },
    avo: {
      apiKey: '',
    },
    funnelRelay: {
      customerId: '',
      scriptSource: '',
    },
    tags: [],
    jwplayer: true,
  },
  features: {
    affiliateGallery: {
      enabled: true,
      apiBaseUrl: 'https://se-affiliate-gallery.aws.aller.com',
      apiEndpoint: '/api/v2/sites/[site]/populated/',
    },
    nextVideoRecommendation: {
      enabled: true,
    },
    payments: {
      enabled: false,
      closeTime: '',
      discountEndTime: '',
      prices: {
        regular: '',
        discount: '',
      },
    },
    recommendedArticles: {
      enabled: true,
    },
    strossle: {
      enabled: false,
      id: '',
    },
    login: {
      enabled: false,
      brandName: '',
    },
    videoReels: {
      scriptSrc: '',
      playlistId: '',
      enabled: false,
    },
    infinityScrollArticle: {
      enabled: false,
    },
    infinityScrollFront: {
      enabled: true,
      title: 'Mest läst',
      mode: 'front',
    },
    direktBanner: {
      enabled: false,
      excludedLabradorPageTypes: [],
    },
    banner: {
      enabled: false,
      excludedLabradorPageTypes: [],
      excludedLabradorMetaPageTypes: [],
    },
    keesingGames: {
      enabled: false,
      customerId: '',
      scriptPath: '',
      stylesPath: '',
      frontPagePathname: '',
    },
    highlightedArticles: {
      enabled: true,
      excludeUrls: [],
      excludedLabradorPageTypes: [],
    },
    direkt: {
      enabled: false,
      id: '',
      aId: '',
      headline: '',
      description: '',
      seoTitle: '',
      seoDescription: '',
    },
    trendingMenu: {
      enabled: true,
      excludeUrls: [],
      excludedLabradorPageTypes: [],
      label: 'Populärt: ',
      tagPrefixes: [],
    },
  },
  pages: {
    author: {
      enabled: true,
      headline: 'Våra skribenter',
      description: '',
      requestArticles: {
        extraQuery: '',
      },
    },
    instagram: {
      enabled: true,
    },
    video: {
      enabled: true,
    },
    '21-dagar': {
      enabled: false,
    },
    direkt: {
      enabled: false,
    },
  },
  seo: {
    schemaMarkup: {
      name: '',
      foundingDate: '',
      alternateName: [''],
      sameAs: [''],
      webPages: [''],
      publishingPrinciples: '',
      verificationFactCheckingPolicy: '',
      correctionsPolicy: '',
      unnamedSourcesPolicy: '',
      missionCoveragePrioritesPolicy: '',
    },
  },
  contentBox: {
    articleTeaser: {
      fieldSettings: {
        title: {
          textSize: {
            allowCmsOverride: true,
            default: {
              desktop: 40,
              mobile: 30,
            },
            fieldFunction: 'textSize',
          },
        },
        subtitle: {
          textSize: {
            allowCmsOverride: true,
            default: {
              desktop: 20,
              mobile: 20,
            },
            fieldFunction: 'textSize',
          },
        },
      },
    },
    textTitle: {
      fieldSettings: {
        title: {
          textAlign: {
            allowCmsOverride: true,
            default: {
              desktop: 'left',
              mobile: 'left',
            },
          },
          textBackgroundColor: {
            allowCmsOverride: true,
            default: {
              desktop: '',
              mobile: '',
            },
          },
          textColor: {
            allowCmsOverride: true,
            default: {
              desktop: '',
              mobile: '',
            },
          },
          italic: {
            allowCmsOverride: true,
            default: {
              desktop: '',
              mobile: '',
            },
          },
        },
      },
    },
    textSubtitle: {
      fieldSettings: {
        subtitle: {
          textAlign: {
            allowCmsOverride: true,
            default: {
              desktop: 'left',
              mobile: 'left',
            },
          },
          textBackgroundColor: {
            allowCmsOverride: true,
            default: {
              desktop: '',
              mobile: '',
            },
          },
          textColor: {
            allowCmsOverride: true,
            default: {
              desktop: '',
              mobile: '',
            },
          },
          italic: {
            allowCmsOverride: true,
            default: {
              desktop: '',
              mobile: '',
            },
          },
        },
      },
    },
    jwVideo: {
      fieldSettings: {
        title: {
          textSize: {
            allowCmsOverride: true,
            default: {
              desktop: 40,
              mobile: 30,
            },
            fieldFunction: 'textSize',
          },
        },
      },
    },
    markup: {
      fieldSettings: {
        markup: {
          dataValue: true,
        },
      },
    },
  },
  mobileApp: {
    enabled: false,
    meta: {
      'al:android:url': '',
      'al:android:app_name': '',
      'al:android:package': '',
      'al:ios:url': '',
      'al:ios:app_store_id': '',
      'al:ios:app_name': '',
      'al:web:should_fallback': '',
    },
  },
  ads: {
    video: {
      autoPlay: '',
      clickToPlay: '',
      videoPage: '',
    },
    exceptionPaths: {},
  },
  cmp: {
    onetrust_id: '',
  },
  labrador: {
    helperService: 'https://se-labrador-helper-services.labrador.allermedia.io',
  },
  common: {
    redirects: [],
  },
};

export default BaseConfig;
